import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { Formik, ErrorMessage } from "formik"
import * as Yup from "yup"
import axios from "axios"
import config from "../../../config"

import HeaderResources from "../../../components/header_resources"

const validationSchema = Yup.object().shape({
  FirstName: Yup.string().required("This is a required field"),
  LastName: Yup.string().required("This is a required field"),
  EmailAddress: Yup.string().email("Enter a valid email").required("This is a required field"),
  DurationRadio: Yup.string().required("This is a required field"),
  AccessRadio: Yup.string().required("This is a required field"),
  InflationRadio: Yup.string().required("This is a required field"),
  RiskRadio: Yup.string().required("This is a required field"),
  InvestmentRadio: Yup.string().required("This is a required field"),
  AggressiveRadio: Yup.string().required("This is a required field"),
  PortfolioRadio: Yup.string().required("This is a required field"),
  OneBondWhich: Yup.string().required("This is a required field"),
  InvestGold: Yup.string().required("This is a required field"),
  GameShow: Yup.string().required("This is a required field"),
})

export default function RiskProfile() {
  const heroTitle = "Risk Profile Questionnaire"
  const { executeRecaptcha } = useGoogleReCaptcha()
  // const [showSuccessDiv, setShowSuccessDiv] = useState(false)
  const showSuccessDiv = false
  const [errorMessage, setErrorMessage] = useState("")

  const initStateObj = {
    FirstName: "",
    LastName: "",
    EmailAddress: "",
    DurationRadio: "",
    AccessRadio: "",
    InflationRadio: "",
    RiskRadio: "",
    InvestmentRadio: "",
    AggressiveRadio: "",
    PortfolioRadio: "",
    OneBondWhich: "",
    InvestGold: "",
    GameShow: "",
  }

  const formSubmitHandler = async (values, resetFormHandler) => {
    setErrorMessage("")

    if (!executeRecaptcha) {
      return
    }
    const captchaResponse = await executeRecaptcha("risk_profile")

    var bodyFormData = new FormData()

    Object.keys(values).forEach(data => {
      bodyFormData.append(data, values[data])
    })

    bodyFormData.append("g-recaptcha-response", captchaResponse)

    try {
      const riskprofileApiResponse = await axios({
        method: "post",
        url: config.API_BASE_URL + "risk-profile-form-submit.php",
        data: bodyFormData,
      })

      if (riskprofileApiResponse.data.success) {
        //setShowSuccessDiv(true)
        resetFormHandler()
        /*setTimeout(() => {
          setShowSuccessDiv(false)
        }, 8000)*/
        navigate("/resources/tools/thankyou", {
          state: {
            form: "risk-profile",
          },
        })
      } else {
        setErrorMessage(riskprofileApiResponse.data.errors)
      }
    } catch (err) {
      setErrorMessage(err.message)
    }
  }

  return (
    <HeaderResources heroTitle={heroTitle} layout="1Column">
      <div class="innerpage--content innerpage--content-sm pb-0">
        <div class="w-100 mb-5 sm:mmb-5">
          <Link class="back--home" to="/resources/tools">
            <i class="fas fa-chevron-left"></i> <span>Back to Tools</span>
          </Link>
        </div>

        <h2 class="h2 h2-sm">{heroTitle}</h2>
        <p>
          The below questionnaire helps assessing your risk profile. This should take approximately
          5 minutes to complete. All questions are mandatory and need to be completed prior to
          submission. To enhance the accuracy of risk profiling, we recommend answering these based
          on your personal thought process and circumstances and not what seems to be the right
          answer. All information supplied shall remain confidential.
        </p>

        <div class="w-100 mt-5">
          <Formik
            initialValues={initStateObj}
            onSubmit={(values, { resetForm }) => {
              formSubmitHandler(values, resetForm)
            }}
            validationSchema={validationSchema}
          >
            {({ handleChange, values, handleSubmit, errors, touched, handleBlur }) => (
              <form class="form-horizontal mt-4" onSubmit={handleSubmit}>
                <div class="row form-group">
                  <div class="col-12 col-md-6 col-sm-6 col-xs-6">
                    <label htmlFor="FirstName">
                      First Name <span class="required">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="FirstName"
                      id="FirstName"
                      value={values.FirstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage name="FirstName" component="span" className="help-block" />
                  </div>
                  <div class="col-12 col-md-6 col-sm-6 col-xs-6">
                    <label htmlFor="LastName">
                      Last Name <span class="required">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="LastName"
                      id="LastName"
                      value={values.LastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage name="LastName" component="span" className="help-block" />
                  </div>
                </div>

                <div class="row form-group">
                  <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                    <label htmlFor="EmailAddress">
                      Email Address <span class="required">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="EmailAddress"
                      id="EmailAddress"
                      value={values.EmailAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage name="EmailAddress" component="span" className="help-block" />
                  </div>
                </div>

                <div class="hr">
                  <hr />
                </div>

                <div class="form-group">
                  <fieldset class="form-item field required" aria-required="true" required>
                    <legend class="title">
                      1. How long do you intend to invest your savings?{" "}
                      <span class="required">*</span>
                    </legend>
                    <div class="d-flex w-100 flex-column">
                      <div class="radio radio-primary mb-1">
                        <input
                          name="DurationRadio"
                          id="LongTerm"
                          type="radio"
                          value="Long term (more than 10 years)"
                          checked={values.DurationRadio === "Long term (more than 10 years)"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="LongTerm">Long term (more than 10 years)</label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="DurationRadio"
                          id="MediumLongTerm"
                          value="Medium-long term (5-10 years)"
                          type="radio"
                          checked={values.DurationRadio === "Medium-long term (5-10 years)"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="MediumLongTerm">Medium-long term (5-10 years)</label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="DurationRadio"
                          id="MediumTerm"
                          value="Medium term (2-5 years)"
                          type="radio"
                          checked={values.DurationRadio === "Medium term (2-5 years)"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="MediumTerm">Medium term (2-5 years)</label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="DurationRadio"
                          id="ShortTerm"
                          value="Short term (1-2 years)"
                          type="radio"
                          checked={values.DurationRadio === "Short term (1-2 years)"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="ShortTerm">Short term (1-2 years)</label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="DurationRadio"
                          id="Parking"
                          value="Parking (less than 1 year)"
                          type="radio"
                          checked={values.DurationRadio === "Parking (less than 1 year)"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="Parking">Parking (less than 1 year)</label>
                      </div>
                    </div>
                    <ErrorMessage name="DurationRadio" component="span" className="help-block" />
                  </fieldset>
                </div>

                <div class="hr">
                  <hr />
                </div>

                <div class="form-group">
                  <fieldset class="form-item field required" aria-required="true">
                    <legend class="title">
                      2. Will you need to access these funds during the term of the investment?{" "}
                      <span class="required">*</span>
                    </legend>
                    <div class="w-100 d-flex flex-row">
                      <div class="w-25">
                        <div class="radio radio-primary mb-1">
                          <input
                            name="AccessRadio"
                            id="YesAccess"
                            type="radio"
                            value="Yes"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label htmlFor="YesAccess">Yes</label>
                        </div>
                      </div>
                      <div class="w-25">
                        <div class="radio radio-primary mb-1">
                          <input
                            name="AccessRadio"
                            id="NoAccess"
                            value="No"
                            type="radio"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label htmlFor="NoAccess">No</label>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage name="AccessRadio" component="span" className="help-block" />
                  </fieldset>
                </div>

                <div class="hr">
                  <hr />
                </div>

                <div class="form-group">
                  <fieldset class="form-item field required" aria-required="true">
                    <legend class="title">
                      3. Inflation erodes the value of your savings. Growth investing can counter
                      the eroding effect of inflation but will also expose you to the risk of
                      short-term losses. <span class="required">*</span>
                    </legend>
                    <div class="d-flex flex-column">
                      <div class="radio radio-primary mb-1">
                        <input
                          name="InflationRadio"
                          id="ComfortableInflation"
                          type="radio"
                          value="I am comfortable with this trade off to beat inflation"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="ComfortableInflation">
                          I am comfortable with this trade off to beat inflation
                        </label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="InflationRadio"
                          id="ConsciousInflation"
                          value="I am conscious of the risks inflation presents, but would prefer a middle ground"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="ConsciousInflation">
                          I am conscious of the risks inflation presents, but would prefer a middle
                          ground
                        </label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="InflationRadio"
                          id="ErodeInflation"
                          value="Inflation may erode my savings, but I have no tolerance for loss"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="ErodeInflation">
                          Inflation may erode my savings, but I have no tolerance for loss
                        </label>
                      </div>
                    </div>
                    <ErrorMessage name="InflationRadio" component="span" className="help-block" />
                  </fieldset>
                </div>

                <div class="hr">
                  <hr />
                </div>

                <div class="form-group">
                  <fieldset class="form-item field required" aria-required="true">
                    <legend class="title">
                      4. Which of the following risk/return scenarios would you be most comfortable
                      with? <span class="required">*</span>
                    </legend>
                    <div class="d-flex flex-column">
                      <div class="radio radio-primary mb-1">
                        <input
                          name="RiskRadio"
                          id="LowRisk"
                          type="radio"
                          value="Low risk/return (maximum return 6% p.a., mininimum return 3% p.a.)"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="LowRisk">
                          Low risk/return (maximum return 6% p.a., mininimum return 3% p.a.)
                        </label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="RiskRadio"
                          id="ModerateRisk"
                          value="Moderate risk/return (maximum return 8% p.a., minimum return -5% p.a.)"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="ModerateRisk">
                          Moderate risk/return (maximum return 8% p.a., minimum return -5% p.a.)
                        </label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="RiskRadio"
                          id="AboveRisk"
                          value="Above average risk/return (maximum return 12 p.a., minimum return -10% p.a.)"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="AboveRisk">
                          Above average risk/return (maximum return 12 p.a., minimum return -10%
                          p.a.)
                        </label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="RiskRadio"
                          id="HighRisk"
                          value="High risk/return (maximum return 20% p.a., minimum return -25% p.a.)"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="HighRisk">
                          High risk/return (maximum return 20% p.a., minimum return -25% p.a.)
                        </label>
                      </div>
                    </div>
                    <ErrorMessage name="RiskRadio" component="span" className="help-block" />
                  </fieldset>
                </div>

                <div class="hr">
                  <hr />
                </div>

                <div class="form-group">
                  <fieldset class="form-item field required" aria-required="true">
                    <legend class="title">
                      5. What would you do if your investment dropped in value from an initial INR
                      100,000 to INR 85,000? <span class="required">*</span>
                    </legend>
                    <div class="d-flex flex-column">
                      <div class="radio radio-primary mb-1">
                        <input
                          name="InvestmentRadio"
                          id="EntireInvestment"
                          type="radio"
                          value="Move the entire investment to cash"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="EntireInvestment">Move the entire investment to cash</label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="InvestmentRadio"
                          id="SomeInvestment"
                          value="Move some of the investment to cash"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="SomeInvestment">Move some of the investment to cash</label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="InvestmentRadio"
                          id="DoNothing"
                          value="Do nothing"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="DoNothing">Do nothing</label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="InvestmentRadio"
                          id="MoreInvestment"
                          value="Buy more of the investment"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="MoreInvestment">Buy more of the investment</label>
                      </div>
                    </div>
                    <ErrorMessage name="InvestmentRadio" component="span" className="help-block" />
                  </fieldset>
                </div>

                <div class="hr">
                  <hr />
                </div>

                <div class="form-group">
                  <fieldset class="form-item field required" aria-required="true">
                    <legend class="title">
                      6. What is the most aggressive investment you've ever made?{" "}
                      <span class="required">*</span>
                    </legend>
                    <div class="d-flex flex-column">
                      <div class="radio radio-primary mb-1">
                        <input
                          name="AggressiveRadio"
                          id="SharesInvestment"
                          type="radio"
                          value="Shares, technology fund, smaller companies fund"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="SharesInvestment">
                          Shares, technology fund, smaller companies fund
                        </label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="AggressiveRadio"
                          id="EquityMutualFunds"
                          value="Equity Mutual Funds"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="EquityMutualFunds">Equity Mutual Funds</label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="AggressiveRadio"
                          id="DebtMutualFund"
                          value="Debt Mutual Funds"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="DebtMutualFund">Debt Mutual Funds</label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="AggressiveRadio"
                          id="InvestmentProperty"
                          value="Investment Property"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="InvestmentProperty">Investment property</label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input name="AggressiveRadio" id="OwnHome" value="Own home" type="radio" />
                        <label htmlFor="OwnHome">Own home</label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="AggressiveRadio"
                          id="FDBankSavings"
                          value="Fixed Deposits / Bank Savings"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="FDBankSavings">Fixed Deposits / Bank Savings</label>
                      </div>
                    </div>
                    <ErrorMessage name="AggressiveRadio" component="span" className="help-block" />
                  </fieldset>
                </div>

                <div class="hr">
                  <hr />
                </div>

                <div class="form-group">
                  <fieldset class="form-item field required" aria-required="true">
                    <legend class="title">
                      7. If you were investing in an equities portfolio, which of the following
                      would suit you best? <span class="required">*</span>
                    </legend>
                    <div class="d-flex flex-column">
                      <div class="radio radio-primary mb-1">
                        <input
                          name="PortfolioRadio"
                          id="HighReturning"
                          type="radio"
                          value="A portfolio of potentially high-returning equities whose value could rise or fall dramatically"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="HighReturning">
                          A portfolio of potentially high-returning equities, whose value could rise
                          or fall dramatically
                        </label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="PortfolioRadio"
                          id="RegularDividends"
                          value="A blue chip portfolio that pays regular dividends"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="RegularDividends">
                          A blue chip portfolio that pays regular dividends
                        </label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="PortfolioRadio"
                          id="TwoOptions"
                          value="A mixture of the above two options"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="TwoOptions">A mixture of the above two options</label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="PortfolioRadio"
                          id="NotInterested"
                          value="I am not interested in shares"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="NotInterested">I am not interested in shares</label>
                      </div>
                    </div>
                    <ErrorMessage name="PortfolioRadio" component="span" className="help-block" />
                  </fieldset>
                </div>

                <div class="hr">
                  <hr />
                </div>

                <div class="form-group">
                  <fieldset class="form-item field required" aria-required="true">
                    <legend class="title">
                      8. Assuming you are investing in only one bond, which bond do you choose?{" "}
                      <span class="required">*</span>
                    </legend>
                    <div class="d-flex flex-column">
                      <div class="radio radio-primary mb-1">
                        <input
                          name="OneBondWhich"
                          id="HighYield"
                          type="radio"
                          value="A high-yield (junk) low credit rated bond that pays a higher interest rate than the other two bonds, but also gives you the least sense of security with regard to a possible default and loss of principal"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="HighYield">
                          A high-yield (junk) low credit rated bond that pays a higher interest rate
                          than the other two bonds, but also gives you the least sense of security
                          with regard to a possible default and loss of principal
                        </label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="OneBondWhich"
                          id="WellEstablished"
                          value="The bond of a well-established company that pays a rate of interest somewhere between the other two bonds"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="WellEstablished">
                          The bond of a well-established company that pays a rate of interest
                          somewhere between the other two bonds
                        </label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="OneBondWhich"
                          id="TaxFree"
                          value="A tax-free bond, since minimizing taxes is your primary investment objective"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="TaxFree">
                          A tax-free bond, since minimizing taxes is your primary investment
                          objective
                        </label>
                      </div>
                    </div>
                    <ErrorMessage name="OneBondWhich" component="span" className="help-block" />
                  </fieldset>
                </div>

                <div class="hr">
                  <hr />
                </div>

                <div class="form-group">
                  <fieldset class="form-item field required" aria-required="true">
                    <legend class="title">
                      9. You expect inflation to return and it has been suggested that you invest in
                      gold, which have historically outpaced inflation. Your only financial assets
                      are fixed deposits. What do you do? <span class="required">*</span>
                    </legend>
                    <div class="d-flex flex-column">
                      <div class="radio radio-primary mb-1">
                        <input
                          name="InvestGold"
                          id="HoldOnToFD"
                          type="radio"
                          value="Ignore the advice and hold on to the fixed deposits"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="HoldOnToFD">
                          Ignore the advice and hold on to the fixed deposits
                        </label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="InvestGold"
                          id="ExitFDHalfGold"
                          value="Exit from fixed deposits, putting half the proceeds in gold and the other half in debt mutual funds that can outperform inflation"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="ExitFDHalfGold">
                          Exit from fixed deposits, putting half the proceeds in gold and the other
                          half in debt mutual funds that can outperform inflation
                        </label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="InvestGold"
                          id="ExitFDAllGold"
                          value="Exit from fixed deposits and put all the proceeds in gold"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="ExitFDAllGold">
                          Exit from fixed deposits and put all the proceeds in gold
                        </label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="InvestGold"
                          id="BorrowAdditional"
                          value="Exit from fixed deposits, put the proceeds in gold, and borrow additional money so you can buy even more gold"
                          type="radio"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="BorrowAdditional">
                          Exit from fixed deposits, put the proceeds in gold, and borrow additional
                          money so you can buy even more gold
                        </label>
                      </div>
                    </div>
                    <ErrorMessage name="InvestGold" component="span" className="help-block" />
                  </fieldset>
                </div>

                <div class="hr">
                  <hr />
                </div>

                <div class="form-group">
                  <fieldset class="form-item field required" aria-required="true">
                    <legend class="title">
                      10. You have just reached the INR 10,000 prize on a TV game show. Now you must
                      choose between quitting with the INR 10,000 in hand or betting the entire INR
                      10,000 in one of three alternative scenarios. Which do you choose?{" "}
                      <span class="required">*</span>
                    </legend>
                    <div class="d-flex flex-column">
                      <div class="radio radio-primary mb-1">
                        <input
                          name="GameShow"
                          id="TakeMoneyQuit"
                          type="radio"
                          value="INR 10,000 - you take the money and quit"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="TakeMoneyQuit">
                          INR 10,000 - you take the money and quit
                        </label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="GameShow"
                          id="50PercentChance"
                          type="radio"
                          value="A 50 percent chance of winning INR 50,000"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="50PercentChance">
                          A 50 percent chance of winning INR 50,000
                        </label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="GameShow"
                          id="20PercentChance"
                          type="radio"
                          value="A 20 percent chance of winning INR 75,000"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="20PercentChance">
                          A 20 percent chance of winning INR 75,000
                        </label>
                      </div>

                      <div class="radio radio-primary mb-1">
                        <input
                          name="GameShow"
                          id="5PercentChance"
                          type="radio"
                          value="A 5 percent chance of winning INR 100,000"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="5PercentChance">
                          A 5 percent chance of winning INR 100,000
                        </label>
                      </div>
                    </div>
                    <ErrorMessage name="GameShow" component="span" className="help-block" />
                  </fieldset>
                </div>

                <div class="hr">
                  <hr />
                </div>

                <button
                  type="submit"
                  class="c-button c-button--blue c-button-radius c-button-inline c-button-lg"
                >
                  Submit
                </button>

                {showSuccessDiv && (
                  <div class="alert alert-success mt-3" role="alert">
                    <strong>Thanks for the response. We'll get back to you.</strong>
                  </div>
                )}

                {errorMessage && (
                  <div class="alert alert-danger mt-3" role="alert">
                    <strong>{errorMessage}</strong>
                  </div>
                )}
              </form>
            )}
          </Formik>
        </div>
      </div>
    </HeaderResources>
  )
}
